body[data-layout="horizontal"] .margin-custom {
    margin-top: 30px !important;
    /* padding: calc(55px + 24px) calc(24px * 0.5) 60px calc(24px * 0.5);
    height: calc(100vh - 130px);
    display: flex;
    align-items: center; */
}
body[data-sidebar="dark"] .vertical-menu,body[data-sidebar="dark"] .navbar-brand-box {
    background: #120e3a !important;
}

ul#side-menu {
    height: calc(100vh - 121px);
    padding-bottom: 30px;
    overflow: auto;
}
.logout_button {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #3e3b3b;
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    padding: 0.625rem 1.5rem;
    color: #545a6d;
    font-size: 13px;
    transition: all .4s;
}
.logout_button i {
    min-width: 2rem;
}

.metismenu {
    background-color: #282356e6 !important;
}


body[data-sidebar="dark"] #sidebar-menu ul li.setting-active a i {
    color: #a6b0cf !important
}
body[data-sidebar="dark"] #sidebar-menu ul li.mm-active a,body[data-sidebar="dark"] #sidebar-menu ul li:hover a, body[data-sidebar="dark"] #sidebar-menu ul li.mm-active a i, .logout_button:hover {
    color: #fff;
    background: #120e3a;
}
ol.breadcrumb.m-0{
    display: none;
}

#sidebar-menu .mm-active > .has-arrow,body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu a:hover ,body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu a:hover i{
    background: #120e3a !important;
    color: #fff !important
}
body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu a,body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu a i{
    background: #2a2558 !important;

}
body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu .mm-active a,body[data-sidebar="dark"] #sidebar-menu ul li.setting-active .sub-menu .mm-active a i{
    background: #120e3a !important;

}
/*=---Login Page-----*/
.custom-login-css{
    
    padding: 80px 0;
    background: #282356e6 url(https://aurres.block-brew.com/images/landing_page_template_with_flowing_lines_design_28055454-[Converted]\ 2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
}
.custom-login-css .bg-primary.bg-soft {
    background-color: #120e3a !important;
    box-shadow: 14px 0px 23px 2px rgb(0 0 0 / 32%);
}
.custom-login-css .bg-primary.bg-soft h5.text-primary {
    color: #fff !important;
}
.custom-login-css .bg-primary.bg-soft p {
    color: #fff !important;
}
.custom-login-css form.form-horizontal button{
    background-color: #120e3a !important;
    border:none;
    outline:0 !important;
}
.custom-login-css .btn-check:focus + .btn, .btn:focus{box-shadow: none !important;}

.btn.btn-primary{
    background-color: #282356e6 !important;
    color: white !important;
    border: none !important;
}

.pagination .page-item.active .page-link{
    background-color: #282356e6 !important;
    color: white !important;
    border: 2px #282356e6 !important;
} 

.customloader .spinner-chase .chase-dot::after {
    color: #282356e6 !important;
}
.page-title-box{
    padding-bottom: 0 !important;
}
.userAccount .accountDetails li b{
    min-width: 140px !important;
    display: inline-block;
}
.action-btn {
    color: #fff;
    border: none;
    background: #282356e6;
}